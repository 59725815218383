import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import uuid from 'uuid'
import WPBlock from '../components/WPBlock'
import { ContextProvider } from './BlockContext'
import * as common from './common'
import * as embed from './embed'
import * as format from './format'
import * as layout from './layout'

/**
 * Hashmap of all of the different blocks and their respective locations.
 *
 * @typedef BlockMap
 * @property {String} blockFileName Name of file containing React component
 * @property {String} group Name of group it is in (e.g., common, embed, format, etc.)
 * @property {Boolean} shouldLoadJavascript Does the component need javascript to be rendered properly?
 */
const blockMap = {
  'lazyblock/script': {
    component: embed.Script,
    blockFileName: 'script',
    group: 'embed',
    shouldLoadJavascript: true,
  },
  'lazyblock/section': {
    component: layout.Section,
    blockFileName: 'section',
    group: 'layout',
    shouldLoadJavascript: false,
  },
  'lazyblock/section-callout': {
    component: layout.SectionCallout,
    blockFileName: 'section-callout',
    group: 'layout',
    shouldLoadJavascript: false,
  },
  'lazyblock/accordion': {
    component: common.Accordion,
    blockFileName: 'accordion',
    group: 'common',
    shouldLoadJavascript: true,
  },
  'lazyblock/section-media': {
    component: layout.SectionAndMedia,
    blockFileName: 'section-media',
    group: 'layout',
    shouldLoadJavascript: false,
  },
  'lazyblock/icon': {
    component: common.Icon,
    blockFileName: 'icon',
    group: 'common',
    shouldLoadJavascript: false,
  },
  'lazyblock/numbered-card': {
    component: common.NumberedCard,
    blockFileName: 'numbered-card',
    group: 'common',
    shouldLoadJavascript: false,
  },
  'lazyblock/section-label': {
    component: common.SectionLabel,
    blockFileName: 'section-label',
    group: 'common',
    shouldLoadJavascript: false,
  },
  'core/columns': {
    component: layout.Columns,
    blockFileName: 'columns',
    group: 'layout',
    shouldLoadJavascript: false,
  },
  'core/buttons': {
    component: layout.Buttons,
    blockFileName: 'buttons',
    group: 'layout',
    shouldLoadJavascript: false,
  },
  'core/column': {
    component: layout.Column,
    blockFileName: 'column',
    group: 'layout',
    shouldLoadJavascript: false,
  },
  'core/group': {
    component: layout.Group,
    blockFileName: 'group',
    group: 'layout',
    shouldLoadJavascript: false,
  },
  'core/media-text': {
    component: layout.MediaAndText,
    blockFileName: 'media-text',
    group: 'layout',
    shouldLoadJavascript: true,
  },
  'core/file': {
    component: common.File,
    blockFileName: 'file',
    group: 'common',
    shouldLoadJavascript: false,
  },
  'core/button': {
    component: common.Button,
    blockFileName: 'button',
    group: 'common',
    shouldLoadJavascript: false,
  },
  'core-embed/youtube': {
    component: embed.YoutubeEmbed,
    blockFileName: 'youtube',
    group: 'embed',
    shouldLoadJavascript: false,
  },
  'lazyblock/embed-file': {
    component: embed.EmbedFile,
    blockFileName: 'embed-file',
    group: 'embed',
    shouldLoadJavascript: true,
  },
  'core/html': {
    component: format.HTML,
    blockFileName: 'html',
    group: 'format',
    shouldLoadJavascript: false,
  },
  'core/image': {
    component: common.ImageBlock,
    blockFileName: 'image',
    group: 'common',
    shouldLoadJavascript: false,
  },
  'lazyblock/image-gallery': {
    component: common.ImageGallery,
    blockFileName: 'image-gallery',
    group: 'common',
    shouldLoadJavascript: true,
  },
  'lazyblock/card': {
    component: layout.Card,
    blockFileName: 'card',
    group: 'layout',
    shouldLoadJavascript: false,
  },
}

export const isServer = () => typeof window === 'undefined'

/**
 * Gets the WP block component based on the name of the block passed in
 * @example
 * const blockData = getBlockFromMap("core/button")
 * @param {String} name block name (ex: core/button, core/paragraph, etc.)
 * @return {JSX.Element} Block
 */
export function getBlockFromMap(name) {
  if (!blockMap.hasOwnProperty(name)) {
    return blockMap['core/html']
  }

  return blockMap[name]
}

/**
 * Builds a page based on the blocks passed
 * PageBuilder is optimized for efficient rendering of static vs. non-static components.
 */
const PageBuilder = ({ blocks, content, meta }) => (
  <ContextProvider value={{ meta, content }}>
    {blocks.map(block => {
      const key = uuid.v4()
      return <WPBlock key={key} block={block} />
    })}
  </ContextProvider>
)

PageBuilder.propTypes = {
  /**
   * Blocks received from WordPress
   */
  blocks: PropTypes.array.isRequired,

  /**
   * Content received from WordPress
   */
  content: PropTypes.string,

  /**
   * Global meta data received from WordPress
   */
  meta: PropTypes.object,
}

export default PageBuilder

/**
 * With the current version of gatsby, it is difficult to grab dynamic properties when building
 * pages with dynamic content. The workaround is to go to the GraphiQL debugger (localhost:9000/____graphql)
 * and select all of the fields under wordpress__PAGEBlocks and include them in the
 * below snippet.
 *
 * The same goes for inner blocks and blog post blocks
 */

// TODO: find a way to not use the below fragments as they are hard to maintain
export const graphqlBlockFragments = graphql`
  fragment WPBlock on wordpress__PAGEBlocks {
    blockName
    attrs {
      blockId
      blockUniqueClass
      background
      align
      sectionID
      media
      background_color
      type
      className
    }
    innerHTML
    innerContent
    innerBlocks {
      blockName
      innerContent
      innerHTML
      innerBlocks {
        innerBlocks {
          attrs {
            svg
            size
            level
            isCentered
            className
            blockUniqueClass
            align
            number
            variant
            link
            blockId
            hasBackground
            url
            customFontSize
            providerNameSlug
            type
            placeholder
            fontSize
          }
          blockName
          innerContent
          innerHTML
          innerBlocks {
            blockName
            innerContent
            innerHTML
            attrs {
              align
              level
              blockUniqueClass
              hasBackground
              isCentered
              svg
              className
              wordpress_id
              link
              blockId
              sizeSlug
              width
              height
            }
            innerBlocks {
              blockName
              innerContent
              innerHTML
              attrs {
                blockId
                blockUniqueClass
                hasBackground
                isCentered
                level
                svg
                align
              }
            }
          }
        }
        innerContent
        innerHTML
        blockName
        attrs {
          className
          fontSize
          placeholder
          width
        }
      }
      attrs {
        align
        className
        level
        mediaPosition
        mediaId
        wordpress_id
        label
        blockUniqueClass
        gallery
        tabs
        height
        fontSize
        sizeSlug
        mediaWidth
        mediaType
        verticalAlignment
        width
        textColor
        blockId
        mediaLink
        isCentered
        isStackedOnMobile
        customFontSize
        type
        url
        imageFill
      }
    }
  }

  fragment WPInnerBlock on wordpress__PAGEBlocksInnerBlocks {
    blockName
    innerHTML
    innerContent
    attrs {
      align
      level
      className
      wordpress_id
      mediaPosition
      mediaType
      mediaId
      blockUniqueClass
      label
      isCentered
      gallery
      tabs
      mediaWidth
      height
      fontSize
      sizeSlug
      textColor
      verticalAlignment
      blockId
      mediaLink
      customFontSize
      isStackedOnMobile
    }
    innerBlocks {
      blockName
      innerContent
      innerHTML
      innerBlocks {
        blockName
        innerContent
        innerHTML
        attrs {
          align
          blockUniqueClass
          className
          isCentered
          level
          size
          svg
          number
          variant
          link
          blockId
          hasBackground
          url
          customFontSize
          providerNameSlug
          type
          placeholder
          fontSize
        }
        innerBlocks {
          attrs {
            align
            level
            isCentered
            hasBackground
            blockUniqueClass
            svg
            className
            wordpress_id
            link
            blockId
            sizeSlug
            width
            height
          }
          blockName
          innerContent
          innerHTML
          innerBlocks {
            blockName
            innerContent
            innerHTML
            attrs {
              blockId
              blockUniqueClass
              hasBackground
              isCentered
              level
              svg
              align
              height
              width
            }
          }
        }
      }
      attrs {
        className
        fontSize
        placeholder
        width
      }
    }
  }

  fragment WPBlockPost on wordpress__POSTBlocks {
    innerHTML
    innerContent
    attrs {
      className
      providerNameSlug
      type
      url
    }
    blockName
  }
`
