import React from 'react'
import uuid from 'uuid'
import WPBlock from '../../components/WPBlock'

const Columns = ({ block }) => {
  if (!block || !block.innerBlocks) {
    return null
  }

  const numCols = block.innerBlocks.length

  return (
    <div className={`wp-block-columns has-${numCols}-columns`}>
      {block.innerBlocks.map(innerBlock => {
        const key = uuid.v4()
        return <WPBlock key={key} block={innerBlock} />
      })}
    </div>
  )
}

export default Columns
