const siteUrl = 'https://' + process.env.S3_BUCKET

module.exports = {
  title: 'Data Fusion Specialists',
  siteUrl,
  wordpressUrl: 'https://wordpress.dfs.team',
  twitterHandle: '@DataFusionUSA',
  landingPageTitle: 'Data Fusion Specialists: Integrity with Purpose',
  description:
    'Data Fusion Specialists is a trusted global company and partner that provides high quality IT Solutions, Services and Resources to accelerate your business and productivity by driving digital transformation to the markets that need it.',
  image: './static/img/logo.png',
  organization: {
    name: 'Data Fusion Specialists',
    url: siteUrl,
    logo: './static/img/logo.png',
  },
  social: {
    twitter: '@DataFusionUSA',
    fbAppID: '',
  },
}
