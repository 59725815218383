import React, { useState } from 'react'
import { ChevronRight } from 'react-feather'

const Tab = ({ id, tab, activeTab, setActiveTab }) => {
  const expanded = activeTab === id

  return (
    <div
      key={tab.label}
      className={`accordion-tab ${expanded ? 'expanded' : ''}`}
      onClick={e => {
        e.preventDefault()
        setActiveTab(id)
      }}
    >
      <div className="accordion-tab-header">
        <div
          className={`is-flex space-between is-vcentered fd-r ${
            expanded ? 'mb-4' : ''
          }`}
        >
          <h4 className="accordion-tab-header-label m-0">{tab.label}</h4>
          <ChevronRight size={20} className="accordion-tab-header-icon" />
        </div>
      </div>
      <div className={`accordion-tab-content`}>{tab.content}</div>
    </div>
  )
}

const Accordion = ({ block }) => {
  if (!block.attrs.tabs) {
    return null
  }

  const tabs = JSON.parse(decodeURIComponent(block.attrs.tabs))
  const [activeTab, setActiveTab] = useState(0 + '-' + tabs[0].label)

  return (
    <div className="wp-block-accordion">
      <div className={`accordion-tabs`}>
        {tabs.map((tab, index) => (
          <Tab
            key={index + '-' + tab.label}
            id={index + '-' + tab.label}
            tab={tab}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        ))}
      </div>
    </div>
  )
}

export default Accordion
