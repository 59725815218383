import React from 'react'
import uuid from 'uuid'
import WPBlock from '../../components/WPBlock'

const Section = ({ block }) => {
  if (!block || !block.innerBlocks) {
    return null
  }

  const hasLabel =
    block.innerBlocks.filter(b => b.blockName === 'lazyblock/section-label')
      .length > 0

  return (
    <section
      className={`section ${hasLabel ? ' with-label' : ''}${
        block.attrs.background ? ` with-bg-${block.attrs.background}` : ''
      }`}
      id={block.attrs.sectionID || ''}
    >
      <div className="container content">
        {block.innerBlocks.map(innerBlock => {
          const key = uuid.v4()

          return <WPBlock key={key} block={innerBlock} />
        })}
      </div>
    </section>
  )
}

export default Section
