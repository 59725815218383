import parser from 'html-react-parser'
import React, { useEffect } from 'react'

const MediaText = ({ block }) => {
  if (!block || !block.innerHTML || !block.innerBlocks || !block.innerContent) {
    return null
  }

  const [loading, setLoading] = React.useState(true)
  const innerContent = React.useRef([])

  useEffect(() => {
    innerContent.current = [...block.innerContent]
    const nullIndexes = block.innerContent.reduce((acc, value, idx) => {
      if (!value) {
        acc.push(idx)
      }
      return acc
    }, [])
    block.innerBlocks.forEach((block, idx) => {
      const idxToReplace = nullIndexes[idx]

      let innerHTML = block.innerHTML
      innerContent.current[idxToReplace] = innerHTML
    })
    setLoading(false)
  }, [block.innerContent, block.innerBlocks])

  if (loading) {
    return parser(block.innerHTML)
  }

  const html = innerContent.current.join('')

  return parser(html, {})
}

export default MediaText
