import React from 'react'
import uuid from 'uuid'
import WPBlock from '../../components/WPBlock'

const SectionMedia = ({ block }) => {
  if (!block || !block.innerBlocks) {
    return null
  }

  const media = JSON.parse(decodeURIComponent(block.attrs.media))
  const hasLabel =
    block.innerBlocks.filter(b => b.blockName === 'lazyblock/section-label')
      .length > 0

  return (
    <section
      className={`section-media ${hasLabel ? 'with-label' : ''}`}
      id={block.attrs.sectionID || ''}
    >
      <div className="section-media-content">
        <div className="content">
          {block.innerBlocks.map(innerBlock => {
            const key = uuid.v4()

            return <WPBlock key={key} block={innerBlock} />
          })}
        </div>
      </div>
      <div
        className="section-media-media"
        style={{
          backgroundImage: `url(${media.url.replace('http://', 'https://')})`,
        }}
      />
    </section>
  )
}

export default SectionMedia
