import parser, { domToReact } from 'html-react-parser'
import React from 'react'
import { Helmet } from 'react-helmet'

/**
 * Used to render custom js scripts with React Helmet
 *
 * @param {any} block Block object
 */
const Script = ({ block }) => {
  if (!block.attrs.scriptCode) {
    return null
  }

  const options = {
    replace: domNode => {
      if (domNode.name === 'script') {
        const { attribs, children } = domNode
        return (
          <Helmet>
            <script {...attribs}>
              {children && children.length
                ? domToReact(children, options)
                : null}
            </script>
          </Helmet>
        )
      }
    },
  }

  return parser(block.attrs.scriptCode, options)
}

export default Script
