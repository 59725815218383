import React, { useEffect } from 'react'
import uuid from 'uuid'
import WPBlock from '../../components/WPBlock'

const Buttons = ({ block }) => {
  if (!block || !block.innerBlocks) {
    return null
  }

  const ref = React.useRef()
  const outerElTag = block.innerContent[0]
  const styles = /(?:style\s*=)\s*"([^"]*)"/.exec(outerElTag)

  useEffect(() => {
    if (styles && ref) {
      ref.current.setAttribute('style', styles[1])
    }
  }, [ref, styles])

  return (
    <div ref={ref} className="wp-block-buttons">
      {block.innerBlocks.map(innerBlock => {
        const key = uuid.v4()
        return <WPBlock key={key} block={innerBlock} />
      })}
    </div>
  )
}

export default Buttons
